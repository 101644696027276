import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackBarService } from '@services/client/snack-bar.service';
import { SafeAny } from '@src/types/safeAny';
import { GuardsServiceService } from '@services/core/guards/guuads-service.service';
import { LocalStorageService } from '@services/client/local-storage/local-storage.service';
import { DataService } from '@services/client/data/data.service';
import { DialogLoginComponent } from '@ui/dialogs/dialog-login/dialog-login.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isPopupOpen: boolean = false;
  constructor(
    private snackBarService: SnackBarService,
    private guardsServiceService: GuardsServiceService,
    private localStorageService: LocalStorageService,
    private dataService: DataService,
    private dialog: MatDialog,
    public router: Router,
  ) {}

  intercept(
    request: HttpRequest<SafeAny>,
    next: HttpHandler,
  ): Observable<HttpEvent<SafeAny>> {
    const token = request.headers.get('Authorization');
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const ignoredUrls = '/qrcode_scanner';
        const ignoredError = 'CustomURL';
        const isIgnoredUrl = error.url?.includes(ignoredUrls);
        if ([400].includes(error?.status) && isIgnoredUrl) {
          return throwError(() => error);
        }
        if (error?.status === 401) {
          const errorResponse = error.error;
          const registrationError = errorResponse?.errors?.find(
            (err: SafeAny) => err.code === 21,
          );
          if (registrationError) {
            const parsedToken: SafeAny = jwtDecode(token);
            const user = {
              email: parsedToken?.email,
              displayName: parsedToken?.name,
            };
            this.openRegistrationDialogIfNeeded(user);

            return throwError(() => error);
          }
        }
        if (![401, 403, 404, 409].includes(error?.status)) {
          const errorWithToken = {
            ...error,
            method: request.method,
            payload: request.body || 'No payload',
            token: token || 'No token found',
          };
          console.error('HTTP Error with Token:', errorWithToken);
        }
        const errorMessage =
          error.error.error || error.message || error.error.statusText;
        if (!errorMessage.includes(ignoredError)) {
          this.snackBarService.setError(errorMessage);
          setTimeout(() => {
            this.snackBarService.setError('');
          }, 5000);
        }
        return throwError(error);
      }),
    );
  }

  private handleRegistrationRequired() {
    this.guardsServiceService.logOut();
    this.localStorageService.removeUserSocial();
    this.localStorageService.removeIsSocial();
    this.localStorageService.removeCreateUserInfo();
    this.dataService.clearLoginData();
    this.dataService.clearLoginEmail();
    this.router.navigate(['/']).then();
  }

  private openRegistrationDialogIfNeeded(user: SafeAny): void {
    setTimeout(() => {
      if (!this.isPopupOpen && user) {
        this.isPopupOpen = true;
        const dialogRef: MatDialogRef<DialogLoginComponent> = this.dialog.open(
          DialogLoginComponent,
          {
            data: {
              title: 'Would you like to register?',
              message: 'Unfortunately, you are not registered in our system.',
              navigateToLink: '/sign-up',
              textButton: 'Register',
              user: user,
            },
          },
        );
        this.dialog.afterAllClosed.subscribe(() => {
          this.isPopupOpen = false;
        });
        dialogRef.backdropClick().subscribe(() => {
          this.handleRegistrationRequired();
        });
      } else {
        this.handleRegistrationRequired();
      }
    }, 0);
  }
}
